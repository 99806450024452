<template>
  <section class="section">
    <div class="container is-max-desktop">
      <form @submit.prevent="handleLogin">
        <div class="columns is-multiline">
          <div class="column is-offset-4 is-4 field">
            <label class="label">Username</label>
            <div class="control">
              <input v-model="username" required class="input" type="text" />
            </div>
          </div>

          <div class="column is-offset-4 is-4 field">
            <label class="label">Password</label>
            <div class="control">
              <input v-model="password" required class="input" type="password" />
            </div>
          </div>

          <div v-if="error" class="column is-offset-4 is-4 field is-flex">
            <div id="error-msg" class="is-flex-grow-1">
              {{ error }}
            </div>
          </div>

          <div class="column is-offset-4 is-4 field is-flex">
            <button :disabled="validating" type="submit" class="button is-flex-grow-1 is-link">Log in</button>
          </div>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {
      error: null,
      username: null,
      password: null,
      validating: false,
    }
  },

  methods: {
    handleLogin() {
      this.validating = true
      this.validate()
    },

    async validate() {
      try {
        await this.$store.dispatch('login', {
          email: this.username,
          password: this.password,
        })
        this.$router.push({ name: 'Home' })
      } catch (err) {
        console.log(err)
        this.error = 'Wrong credentials'
        this.validating = false
      }
    },
  },
}
</script>

<style>
#error-msg {
  background-color: rgb(223, 83, 83);
  padding: 4px 12px;
  color: white;
  border-radius: 5px;
}
</style>
