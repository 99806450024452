import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'

const firebaseConfig = {
  apiKey: "AIzaSyAfLRVoYYbirG8FwGx1KwWKyFxY33DBsR4",
  authDomain: "despensa-c5b24.firebaseapp.com",
  projectId: "despensa-c5b24",
  storageBucket: "despensa-c5b24.appspot.com",
  messagingSenderId: "491420573174",
  appId: "1:491420573174:web:73ddd2273b2217fff43bd7"
};




// init firebase
initializeApp(firebaseConfig)

// init services
const db = getFirestore()
const auth = getAuth()

export { db, auth }