<template>
  <section class="section">
    <div class="container is-max-desktop" id="search">
      <h1 class="title">Search</h1>

      <div class="columns is-multiline">
        <form @submit.prevent="onSearch">
          <div class="field column is-12 is-grouped">
            <div class="control is-expanded">
              <div class="field has-addons">
                <div class="control is-expanded">
                  <input v-model="title" id="image" class="input" type="text" placeholder="Title" />
                </div>
                <div class="control">
                  <button type="submit" class="button is-info">Search Title</button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <!-- RESULTS -->
      <div class="results">
        {{ message }}
        <div class="result is-flex" v-for="res in results" :key="res.title" @click="onResultClick(res)">
          <img :src="res.img" style="height: 80px" />
          <div style="margin-left: 10px">
            <div style="font-weight: bold">{{ res.title }}</div>
            <div>{{ res.subtitle }}</div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { ref } from '@vue/reactivity'
import { useRouter } from 'vue-router'
const axios = require('axios')
const slugify = require('slugify')

export default {
  props: ['type'],
  setup() {
    const WS_SEARCH = 'https://svd-puppet.herokuapp.com/book/search?q='

    const title = ref('')
    const results = ref([])
    const searching = ref(false)
    const message = ref('')

    const router = useRouter()

    const onSearch = () => {
      searching.value = true
      message.value = 'Searching...'
      results.value = []

      axios
        .get(WS_SEARCH + title.value)
        .then((res) => {
          console.log(res.data)
          results.value = res.data
          message.value = ''
        })
        .catch((err) => {
          console.log(err)
          message.value = 'There was an error'
        })
        .finally(() => {
          searching.value = false
        })
    }

    const onResultClick = (res) => {
      router.push({
        name: 'Book',
        params: {
          id: slugify(res.title, { lower: true }),
          searchTitle: res.title,
          searchImage: res.img,
          searchLink: res.link
        },
      })
    }

    return { title, results, searching, message, onResultClick, onSearch }
  },
}
</script>

<style scoped>
.result {
  margin-bottom: 10px;
  margin-top: 10px;
  cursor: pointer;
}

.result:hover {
  background-color: #17222c;
}
</style>
