<template>
  <section class="section">
    <div class="container is-max-desktop">
      <!-- ITEMS BUY -->
      <div class="items-buy">
        <div v-for="item in buyItems" :key="item.id" class="is-flex">
          <div class="item buy has-background-info is-flex-grow-1" @click="onItemClick(item)">
            {{ item.name }}
          </div>
          <div class="edit-button" @click="onItemEdit(item)">
            <span class="icon is-small">
              <i class="fas fa-edit"></i>
            </span>
          </div>
        </div>
      </div>

      <!-- ITEMS OTHER -->
      <div class="items-other" style="margin-top: 20px;">
        <div v-for="item in otherItems" :key="item.id" class="is-flex is-align-items-center">
          <div class="item is-flex-grow-1" @click="onItemClick(item)">
            {{ item.name }}
          </div>
          <div class="edit-button" @click="onItemEdit(item)">
            <span class="icon is-small">
              <i class="fas fa-edit"></i>
            </span>
          </div>
        </div>
      </div>
    </div>

    <!-- ADD BUTTON -->
    <router-link :to="{ name: 'NewItem' }">
      <div class="floating-btn">
        <span class="icon">
          <i class="fas fa-plus"></i>
        </span>
      </div>
    </router-link>
  </section>
</template>

<script>
import { db } from '../firebase/config'
import { collection, getDocs, orderBy, query, setDoc, doc } from '@firebase/firestore'

export default {
  data() {
    return {
      items: [],
    }
  },

  mounted() {
    this.getItems()
  },

  computed: {
    buyItems() {
      return this.items.filter((i) => i.buy)
    },

    otherItems() {
      return this.items.filter((i) => !i.buy)
    },
  },

  methods: {
    async getItems() {
      const q = query(collection(db, 'items'), orderBy('name', 'asc'))
      const res = await getDocs(q)
      this.items = res.docs.map((doc) => {
        return { id: doc.id, ...doc.data() }
      })
    },

    async onItemClick(item) {
      item.buy = !item.buy
      await setDoc(doc(db, 'items', item.id), item)
    },

    onItemEdit(item) {
      this.$router.push({ name: 'EditItem', params: { id: item.id } })
    },
  },
}
</script>

<style>
.item {
  box-shadow: 2px 2px 3px #c9c9c9;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.item.buy {
  color: white;
  border: 0px;
}

.edit-button {
  box-shadow: 2px 2px 3px #c9c9c9;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 10px;
  cursor: pointer;
  margin-left: 10px;
}

.floating-btn {
  position: fixed;
  width: 60px;
  height: 60px;
  bottom: 20px;
  right: 20px;
  background-color: #5cb85c;
  color: #fff;
  border-radius: 100%;
  text-align: center;
  box-shadow: 2px 2px 3px #c9c9c9;
  display: flex;
  justify-content: center;
  align-items: center;
}

.floating-btn span {
  font-size: 22px;
}
</style>
