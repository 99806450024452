<template>
  <section class="section">
    <div class="container is-max-desktop" v-if="loaded">
      <form @submit.prevent="onSubmit">
        <!-- INPUT NAME -->
        <div class="field">
          <label class="label">Name</label>
          <div class="control">
            <input class="input" type="text" v-model="name" required />
          </div>
        </div>

        <!-- BUTTON BUY -->
        <div>
          <a
            @click="buy = !buy"
            :class="['button', 'is-primary', { 'is-outlined': !buy }, 'is-fullwidth']"
          >
            Comprar
          </a>
        </div>

        <!-- SAVE CANCEL -->
        <div id="buttons">
          <div>
            <button type="submit" class="button is-link is-fullwidth">Guardar</button>
          </div>
          <div style="margin-top: 10px" v-if="id">
            <a @click="onDelete" class="button is-danger is-fullwidth">Borrar</a>
          </div>
          <div style="margin-top: 10px">
            <a @click="onCancel" class="button is-link is-light is-fullwidth">Cancelar</a>
          </div>
        </div>

        <!-- END ITEMS -->
      </form>
    </div>
  </section>
</template>

<script>
const slugify = require('slugify')
import { db } from '../firebase/config'
import { doc, getDoc, setDoc, deleteDoc } from '@firebase/firestore'

export default {
  props: ['id'],

  data() {
    return {
      loaded: false,
      name: '',
      buy: false,
    }
  },

  mounted() {
    if (this.id) {
      this.getItem(this.id)
    } else {
      this.loaded = true
      this.buy = true
    }
  },

  methods: {
    async getItem(id) {
      const docRef = doc(db, 'items', id)
      const item = await getDoc(docRef)
      this.name = item.data().name
      this.buy = item.data().buy
      this.loaded = true
    },

    async onSubmit() {
      let id = this.id
      if (!id) {
        id = slugify(this.name, { lower: true })
      }

      await setDoc(doc(db, 'items', id), {
        name: this.name,
        buy: this.buy,
      })
      this.$router.push({ name: 'Home' })
    },

    onCancel() {
      this.$router.push({ name: 'Home' })
    },

    async onDelete() {
      const docRef = doc(db, 'items', this.id)
      await deleteDoc(docRef);
      this.$router.push({ name: 'Home' })
    },
  },
}
</script>

<style>
#buttons {
  margin-top: 15px;
}
</style>
