<template>
  <section class="section">
    <div class="container is-max-desktop">
      <div class="columns is-mobile">
        <div class="column is-3">
          <img class="image" :src="image" />
          <a
            :href="link"
            target="_blank"
            class="button is-info is-outlined is-fullwidth btn-link"
            >Goodreads</a
          >
        </div>
        <div class="column">
          <h1 class="title">{{ title }}</h1>

          <!-- ADD TAG -->
          <form @submit.prevent="onAddTag">
            <div class="field is-grouped">
              <div class="control">
                <input class="input" type="text" placeholder="Add tag" v-model="newTag" />
              </div>
              <div class="control">
                <button class="button is-link">Add</button>
              </div>
            </div>
          </form>

          <!-- TAGS -->
          <div class="tags are-medium">
            <span :class="tagClasses(tag)" v-for="tag in tags" :key="tag.id" @click="onClickTag(tag)">
              {{ tag.name }}
            </span>
          </div>

          <!-- BUTTONS -->
          <div class="field is-grouped buttons">
            <div class="control">
              <button @click="onSubmit" class="button is-link">Save</button>
            </div>
            <div class="control">
              <button @click="router.push({ name: 'Home' })" class="button is-link is-light">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { useRouter } from 'vue-router'
import { ref } from '@vue/reactivity'
import { onMounted } from '@vue/runtime-core'
import { doc, getDoc, setDoc } from '@firebase/firestore'
import { db } from '../firebase/config'
import getTags from '../composables/getTags'
const slugify = require('slugify')

export default {
  props: ['id', 'searchTitle', 'searchImage', 'searchLink'],
  setup(props) {
    const title = ref('')
    const image = ref('')
    const link = ref('')
    const newTag = ref('')
    const selectedTags = ref(new Set())
    const { tags, loadTags } = getTags()
    const router = useRouter()

    onMounted(async () => {
      await loadTags()
      await getDataFromFirebase()
    })

    const getDataFromFirebase = async () => {
      const docRef = doc(db, 'books', props.id)
      const book = await getDoc(docRef)

      if (book.exists()) {
        title.value = book.data().title
        image.value = book.data().image
        link.value = book.data().link
        selectedTags.value = new Set(book.data().tags)
      } else {
        title.value = props.searchTitle
        image.value = props.searchImage
        link.value = props.searchLink
      }
    }

    const tagClasses = (tag) => ['tag', 'is-clickable', { 'is-primary': selectedTags.value.has(tag.id) }]

    const onClickTag = (tag) => {
      if (selectedTags.value.has(tag.id)) {
        selectedTags.value.delete(tag.id)
      } else {
        selectedTags.value.add(tag.id)
      }
    }

    const onAddTag = () => {
      const slug = slugify(newTag.value, { lower: true })
      if (slug && tags.value.findIndex((tag) => tag.id == slug) == -1) {
        tags.value.push({
          id: slug,
          name: newTag.value,
          isNew: true,
        })
        selectedTags.value.add(slug)
      }
      newTag.value = ''
    }

    const onSubmit = async () => {
      for (const tag of tags.value) {
        if (tag.isNew) {
          await setDoc(doc(db, 'tags', tag.id), { name: tag.name })
        }
      }
      const newItem = {
        title: title.value,
        image: image.value,
        link: link.value,
        tags: [...selectedTags.value],
      }
      await setDoc(doc(db, 'books', props.id), newItem)
      router.push({ name: 'Home' })
    }

    return {
      title,
      image,
      link,
      newTag,
      tags,
      selectedTags,
      onClickTag,
      tagClasses,
      onSubmit,
      onAddTag,
      router,
    }
  },
}
</script>

<style>
.image {
  width: 100%;
  border-radius: 5%;
  box-shadow: 2px 2px 5px rgb(44, 44, 44);
}

.tags {
  margin-top: 20px;
}

.buttons {
  margin-top: 30px;
}

.btn-link {
  margin-top: 10px;
}
</style>
